<template>
    <div class="page">
      <!-- <Head :headTitle="headTitle" /> -->
    </div>
  </template>
  
  <script>
  // import 'assets/css/style.css'
  // import Head from "@/components/head_wm.vue";
  import bgAnimateVue from "@/components/bg_animate.vue";
  
  export default {
    data() {
      return {
        // headTitle: "岳阳南湖新区新时代文明",
      };
    },
    components: {
    //   Head,
      bgAnimateVue,
    },
    computed: {},
    created() {
   
    },
    mounted() {},
    methods: {},
  };
  </script>
  <style scoped>
  .page {
    background: url(https://video-1316200666.cos.ap-guangzhou.myqcloud.com/hunan/hengyang/photo/qidonghuangyiyes.png) no-repeat center;
    background-size: 100% 100%;
  }
  </style>